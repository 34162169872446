@import '_env-variables';

.statements-list-table,
.account-settings-table-container {
  .statements-menu-column,
  .account-settings-menu-column {
    width: 0;
  }
  .mat-mdc-table td.mat-mdc-cell:last-of-type {
    padding: 0 5px;
  }
}

.statement-container {
  .header-title-container {
    .header-chip {
      max-width: 400px !important;
    }
  }

  .mat-divider.mat-divider-vertical {
    padding-left: 18px !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.statement-settings-container {
  width: 550px;
  overflow: hidden;
  .dialog-divider {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }
  .footer-button-container button:nth-child(1),
  .review-button-container button:nth-child(1),
  .settings-control-buttons button:nth-child(1),
  .statement-balance-sources-button {
    margin-left: 0px;
  }
  .review-container {
    margin-right: 0px;
    margin-left: 0px;
    .review-item {
      background: #f0f0f0;
      border: 0;
      border-radius: 8px;
    }
  }
  input.mat-mdc-input-element.date-range-picker-form-field {
    text-align: left !important;
    color: $light-text-color;
  }
}

.statement-settings-container.dialog-theme {
  padding: 0 !important;
  .dialog-theme-inner {
    padding: 0 24px;
  }
}

.mw-490 {
  max-width: 490px;
}

#createStatementDialog {
  .mat-mdc-dialog-surface {
    overflow-x: hidden;
  }
}

.statement-include-weekends-toggle {
  padding-left: 10px;
  margin-bottom: 30px;
  button.mdc-switch {
    margin-left: 280px;
  }
}

.statement-settings-tab-group-container .statement-include-weekends-toggle {
  button.mdc-switch {
    margin-left: 320px;
  }
}

.mat-mdc-menu-item.submenu-list-item {
  overflow: hidden;
}

.mat-mdc-menu-item.active-submenu-list-item {
  color: $primary-color;
}

.mat-submenu-container.mat-mdc-menu-panel {
  max-width: 400px !important;
}

.sources-empty {
	overflow-y: hidden !important;
}

.statements-list-table,
.account-settings-table-container {
  .table-container:has(mat-paginator) tr.mat-mdc-row:last-child td.mat-mdc-cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.tql-expression-stepper-container {
  width: 700px;
  transform: scale(0.8);
  min-height: 80px;
  transform-origin: top left;
  overflow: hidden;
  .tql-search-bar-wrapper {
    mat-form-field.mat-mdc-form-field {
      padding-bottom: 0px !important;
      .mdc-notched-outline {
        display: none;
      }
    }
  }
}

.custom-menu.custom-menu-wide {
  .mat-mdc-menu-content {
    min-width: 290px;
    max-width: 400px;
  }
}

.mat-submenu-container.custom-menu-wide {
  .mat-mdc-menu-content {
    min-width:315px;
    max-width: 400px;
    .mat-mdc-menu-item {
      display: block;
    }
  }
}

.statement-settings-tab-group-container {
  .mat-mdc-tab-body-content {
    overflow: hidden;
  }
}

.v2-chip.v2-chip-small mat-chip.mat-mdc-standard-chip {
  background-color: rgba(210, 210, 210, 0.56);
	.mat-mdc-chip-action {
		padding-left: 7px;
		padding-right: 7px;
	}
}

trovata-statements-list {
  mat-chip.mat-mdc-standard-chip {
    padding-top: 0;
    padding-bottom: 0;
    min-width: 40px;
    --mdc-chip-container-height: 20px !important;
    .mat-mdc-chip-action {
      padding-left: 4px !important;
      padding-right: 4px !important;
      pointer-events: all !important;
    cursor: pointer !important;
    }
    background: $primary-light-color-chip-v2 !important;
    &:hover {
      background: $primary-light-color-chip-hover-v2;
    }
    .chip-text {
      color: $primary-color-chip-v2;
    }
    .mat-icon {
      color: $primary-color-chip-v2;
      vertical-align: middle;
      height: 14px !important;
      width: 14px !important;
      font-size: 14px !important;
      padding: 3px;
    }
  }
  mat-chip.mat-mdc-standard-chip.brown-chip {
    background: #FFEED8 !important;
    .chip-text,
    .mat-icon {
      color: #9A5300 !important;
    }
  }
}

mat-chip.row-chip.brown-chip {
  border: 0;
  line-height: 16px !important;
  min-height: 16px !important;
  height: 16px !important;
  border-radius: 20px;
  padding: 3px 10px 5px 3px;
  background: #FFEED8 !important;
  .chip-text {
    color: #9A5300 !important;
  }
}

.snack-title-container {
  mat-chip.snack-brown-chip {
    background: #FFEED8 !important;
    .chip-text {
      color: #9A5300 !important;
    }
  }
}

.statement-container,
.list-container {
  mat-chip.statement-header-chip {
    height: 24px !important;
  }
  mat-chip.statement-header-chip-brown,
  mat-chip.brown-chip {
    border: 0;
    background: #FFEED8 !important;
    color: #9A5300 !important;
    .mat-mdc-chip-action-label {
      color: #9A5300 !important;
    }
  }
}

.version-view-mode-select-options {
  width: 409px !important;
  border-radius: 8px !important;
}

.version-container {
  .column-controls-name-form {
    margin-left: -5px;
    .mat-mdc-text-field-wrapper {
      padding-left: 5px;
    }
    .column-name-input {
      font-weight: bold;
      font-size: 14px;
      width: 190px;
    }
  }
}
