@use '@angular/material' as mat;

.cash-position-tables {
	td.mat-mdc-cell.black-alpha-88 {
		color: $dark-primary-text;
	}

	td.mat-mdc-cell.black-alpha-56 {
		color: rgba(0, 0, 0, 0.56);
	}

	td.mat-mdc-cell.red-FF5252 {
		color: #ff5252;
	}

	td.mat-column-net.mat-mdc-cell.red-FF5252 {
		.currency-symbol {
			color: $dark-primary-text;
		}
	}

	.mat-column-accountName,
	.mat-column-currencyName {
		min-width: 200px;
		font-weight: $bold;
		border-right: none !important;
	}

	td.mat-column-accountName:hover,
	td.mat-column-currencyName:hover,
	td.mat-column-currentTrxn:hover,
	td.mat-column-actualTrxn:hover,
	td.mat-column-assumedTrxnValue:hover,
	td.mat-column-expectedBal:hover,
	td.mat-column-targetBal:hover {
		background: rgba(0, 0, 0, 0.04);
	}

	.mat-column-targetBal:has(input:focus),
	.mat-column-targetBal:has(input:focus):hover {
		background: rgba(0, 108, 226, 0.08);
	}

	.mat-column-openingBal,
	.mat-column-currentTrxn,
	.mat-column-actualTrxn,
	.mat-column-assumedTrxnValue,
	.mat-column-expectedBal,
	.mat-column-closingBal,
	.mat-column-targetBal,
	.mat-column-net {
		min-width: 130px;
		width: 130px;
		max-width: 130px;
	}

	.mat-column-menu,
	.mat-column-menuSpacer,
	th.mat-mdc-header-cell.mat-column-menuSpacer:last-of-type,
	td.mat-mdc-cell.mat-column-menuSpacer:last-of-type,
	th.mat-mdc-header-cell.mat-column-menu:last-of-type,
	td.mat-mdc-cell.mat-column-menu:last-of-type {
		padding: 0px 8px !important;
		min-width: 40px;
		width: 40px;
		max-width: 40px;
	}

	.currency-chip {
		padding: 2px 10px;
		gap: 4px;
		height: 20px;
		background: rgba(0, 0, 0, 0.12);
		border-radius: 12px;
		margin-left: 12px;
		font-weight: 400;
		font-size: 12px;
	}
}

#cash-position-settings-dialog,
#cash-position-transactions-dialog,
#cash-position-assumptions-dialog,
#cash-position-acct-settings-dialog,
#cash-position-acct-summary-dialog {
	div.mat-mdc-dialog-title {
		margin-bottom: 0px;
	}
}

.nav-menu-content-container:has(.cash-position-view) {
	margin-bottom: 0px;
}

.cash-position-view {
	.comparison-version-container {
		td.new-cell {
			background: rgba(255, 82, 82, 0.08);
		}

		td.new-cell:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(255, 82, 82, 0.08);
		}

		td.changed-cell {
			background: rgba(0, 108, 226, 0.08);
		}

		td.changed-cell:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(0, 108, 226, 0.08);
		}
	}

	.comparison-main-container {
		td.new-cell {
			background: rgba(13, 178, 95, 0.08);
		}

		td.new-cell:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(13, 178, 95, 0.08);
		}
	}
}

.cash-position-tables.highlight-changes {
	td.changed-cell {
		background: rgba(0, 108, 226, 0.08);
	}

	td.changed-cell:hover {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(0, 108, 226, 0.08);
	}

	td.new-cell {
		background: rgba(13, 178, 95, 0.08);
	}

	td.new-cell:hover {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(13, 178, 95, 0.08);
	}
}

.expectedBal-info-panel,
.closingBal-info-panel {
	font-size: 14px;
	padding: 16px;
	min-width: 92px;
	width: 92px;

	.mat-mdc-menu-content:not(:empty) {
		padding: 0px;
	}

	.eqn-divider {
		width: 100%;
		height: 1px;
		margin: 8px 0px;
		background: rgba(0, 0, 0, 0.16);
	}
}
div.mat-mdc-dialog-surface {
	trovata-assumptions-dialog {
		height: 100vh;

		mat-dialog-content {
			max-height: unset;
		}
	}
}

.assumpt-card {
	@include mat.form-field-density(-5);

	td.mat-mdc-cell.mat-column-menu,
	td.mat-column-menu.mat-mdc-cell:last-of-type {
		width: 48px;
		max-width: 48px;
		min-width: 48px;
		padding: 0px;
	}
}

.version-sidenav-container {
	height: 100vh;
}

.version-sidenav.mat-drawer {
	width: 240px;
	background-color: #f0f0f0;
	box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.16);
	height: 100vh;

	.sticky-page-header {
		background-color: #f0f0f0;
		margin-top: 0px;
		padding-left: 16px;
		width: calc(100% - 16px);
	}

	.mat-expansion-panel {
		border-width: 0px;
		border-radius: 0px;
		box-shadow: none;
		margin: 0px;
		padding: 0px;
	}

	.mat-expansion-panel-header {
		height: 82px;
		padding: 0px;
		border-radius: 0px;
	}

	.mat-expansion-panel-header-title,
	.mat-content.mat-content-hide-toggle {
		margin: 0px;
		padding: 0px;
	}

	mat-panel-title {
		width: 100%;
	}

	.mat-expansion-panel-body,
	.mat-list-item-content,
	.mat-list-base .mat-list-item .mat-list-item-content {
		padding: 0px;
	}

	.version-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		padding: 14px 16px;
		background-color: #f0f0f0;

		.mat-icon {
			margin-right: 16px;
		}
	}

	.selected-container .version-item {
		background: rgba(0, 0, 0, 0.04);
	}

	.version-item.selected,
	.version-item:hover,
	.selected-container .version-item.selected,
	.selected-container .version-item:hover {
		background: rgba(0, 108, 226, 0.12);
	}

	.version-item:not(:has(mat-icon)) {
		padding-left: 56px;
	}
}

.version-sidenav-main {
	background-color: white;
	height: 100vh;
}

.cash-position-version-menu.mat-mdc-menu-panel {
	width: 300px;
	max-width: 300px;

	.mat-list-base .mat-list-item {
		width: 272px;
	}
}

.historical-vis-point-menu.mat-mdc-menu-panel {
	.mat-mdc-menu-content {
		padding: 0px;
		width: 240px;
		max-width: 240px;
		height: 156px;
		max-height: 156px;
	}
}

.timeframe-chips {
	.mdc-evolution-chip-set__chips {
		margin-left: 0px !important;
	}
}

trovata-assumptions {
	.mdc-text-field--filled {
		background-color: unset !important;
		padding: 0;
	}

	.mdc-line-ripple {
		display: none;
	}

	.mat-mdc-form-field-focus-overlay {
		display: none;
	}

	.mat-mdc-form-field-subscript-wrapper {
		display: none;
	}

	#cash-pos-assumpt-grid {
		.wj-rowheaders,
		.wj-cells {
			.wj-row:last-of-type .wj-cell {
				border-bottom: none;
			}
		}
		.wj-colheaders,
		.wj-cells {
			.wj-row .wj-cell:last-of-type {
				border-right: none;
			}
		}
	}
	.assumptions-flex-grid .wj-header.wj-cell {
		font-size: 14px;
		font-weight: 700;
		background-color: #F7F7F7;
	}
	
	.assumptions-flex-grid .wj-cell {
		font-size: 14px;
		font-weight: 400;
		background-color: #fff;
	}
	.assumptions-flex-grid .wj-cell:not(.wj-state-invalid):has(> input) {
		box-shadow: inset 0 0 0 2px $primary-color;
	}
	.assumptions-flex-grid .wj-cell:not(.wj-state-invalid) > wj-auto-complete {
		display: flex;
		border-radius: 0px;
		box-shadow: inset 0 0 0 2px $primary-color;
	}
	.assumptions-flex-grid .wj-cell.wj-state-selected:not(.wj-state-invalid) {
		background-color: #FFF !important;
		box-shadow: inset 0 0 0 1px $primary-color;
		color: #000;
	}
	.assumptions-flex-grid .wj-cell.wj-state-selected:not(.wj-state-invalid).first-cell {
		background-color: #C2E4FF !important;
		box-shadow: none !important;
	}
	
	.assumptions-flex-grid .wj-cells .wj-cell.wj-state-multi-selected {
		color: #000;
		background-color: #D8EDFF;
	}
}