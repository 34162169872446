@import '_env-variables';

.disabled-text {
	color: rgba(0, 0, 0, 0.38);
}

.dark-disabled-text,
.dark-disabled-text:disabled {
	color: $dark-primary-text;
}

.bold-title {
	font-weight: $bold;
	font-size: 16px;
	vertical-align: middle;
}

.bold {
	font-weight: $bold;
}

.bold-important {
	font-weight: $bold !important;
}

.italic {
	font-style: italic;
}

.uppercase {
	text-transform: uppercase;
}

.strikethrough {
	text-decoration: line-through;
}

.underline {
	text-decoration: underline;
}

.page-header-title {
	font-size: 16px;
	font-weight: 500;
	height: 24px;
	display: flex;
	align-items: center;
}

.trovata-title {
	color: #1d242e;
	font-size: 1.5rem;
	font-weight: 500;
	margin-bottom: 10px;
	padding-bottom: 15px;
	position: relative;
	margin-top: 0px;

	&::after {
		background-color: $primary-color;
		bottom: 0;
		content: '';
		height: 2px;
		left: 0;
		position: absolute;
		width: 46px;
	}
}

.trovata-subtitle {
	font-size: 14px;
	color: #8e9297;
}

.trovata-title-2 {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
}

.trovata-subtitle-2 {
	font-size: 14px;
	line-height: 24px;
	color: $light-text-color;
}

.trovata-blue-text {
	color: #006ce2;
}

.white {
	color: white !important;
}

.green {
	color: green;
}

.green-0DB25F {
	color: #0db25f;
}

.green-0DB25F-important {
	color: #0db25f !important;
}

.green-1B5E20 {
	color: #1b5e20;
}

.red {
	color: red;
}

.red-important {
	color: red !important;
}

.red-important-focus:not(:focus) {
	color: red !important;
}

.red-FF5252 {
	color: #ff5252;
}

.red-FF5252-important {
	color: #ff5252 !important;
}

.red-C54243 {
	color: #c54243;
}

.red-B71C1C {
	color: #b71c1c;
}

.red-D32F2F {
	color: #d32f2f;
}

.red-D5255B-important {
	color: #d5255b !important;
}

.red-D5255B {
	color: #d5255b;
}

.red-D5245B-important {
	color: #d5245b !important;
}

.orange {
	color: orange;
}

.orange-FB8C00 {
	color: #fb8c00;
}

.primary-color {
	color: $primary-color;
}

.primary-color-important {
	color: $primary-color !important;
}

.blue-006CE2 {
	color: #006ce2;
}

.blue-006CE2-important {
	color: #006ce2 !important;
}

.green-038142 {
	color: #038142;
}

.green-00813C {
	color: #00813c;
}

.green-038142-important {
	color: #038142 !important;
}

.green-00813C-important {
	color: #00813c !important;
}

.blue-003A5C {
	color: #003a5c;
}

.blue-003A5D {
	color: #003a5d;
}

.brown-893C00 {
	color: #893c00;
}

.brown-A95C00 {
	color: #a95c00;
}

.brown-A95C00-important{
	color: #a95c00 !important;
}

.black-1C1E1C {
	color: #1c1e1c;
}

.black-alpha-56 {
	color: $light-text-color;
}

.black-alpha-36 {
	color: rgba(0, 0, 0, 0.36);
}

.black-alpha-88 {
	color: $dark-primary-text;
}

.grey-0000008F {
	color: #0000008f;
}

.letter-spacing-5 {
	letter-spacing: 0.5px;
}

.font-400-12-12 {
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
}

.font-400-12-16 {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
}

.font-400-12-20 {
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
}

.font-400-14-16 {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
}

.font-400-14-20 {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.font-400-14-24 {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
}

.font-400-14-28 {
	font-weight: 400;
	font-size: 14px;
	line-height: 28px;
}

.font-400-16-24 {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.font-400-20-28 {
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
}

.font-400-24-28 {
	font-weight: 400;
	font-size: 24px;
	line-height: 28px;
}

.font-400-24-36 {
	font-weight: 400;
	font-size: 24px;
	line-height: 36px;
}

.font-400-28-32 {
	font-weight: 400;
	font-size: 28px;
	line-height: 32px;
}

.font-400-28-36 {
	font-weight: 400;
	font-size: 28px;
	line-height: 36px;
}

.font-400-48-48 {
	font-weight: 400;
	font-size: 48px;
	line-height: 48px;
}

.font-400-18-26 {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.font-700-12-16 {
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
}

.font-700-12-20 {
	font-weight: 700;
	font-size: 12px;
	line-height: 20px;
}

.font-700-13-16 {
	font-weight: 700;
	font-size: 13px;
	line-height: 16px;
}

.font-700-13-20 {
	font-weight: 700;
	font-size: 13px;
	line-height: 20px;
}

.font-700-14-16 {
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
}

.font-700-14-20 {
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
}

.font-700-14-24 {
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
}

.font-700-16-24 {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
}

.font-700-16-28 {
	font-weight: 700;
	font-size: 16px;
	line-height: 28px;
}

.font-700-16-40 {
	font-weight: 700;
	font-size: 16px;
	line-height: 40px;
}

.font-700-18-26 {
	font-weight: 700;
	font-size: 18px;
	line-height: 26px;
}

.font-700-20-28 {
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
}

.font-700-24-32 {
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
}

.clickable-text:hover {
	cursor: pointer;
	text-decoration: underline;
}

.font-700-10-15 {
	font-weight: 700;
	font-size: 10px;
	line-height: 15px;
}

.font-400-10-15 {
	font-weight: 400;
	font-size: 10px;
	line-height: 15px;
}
