trovata-account-summary-selectors .mat-mdc-text-field-wrapper {
    padding-bottom: unset !important;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none !important;
    background: none !important;

    .mat-mdc-form-fields-flex{
        .mat-mdc-form-text-infix {
            padding: 0 17px 0 17px !important;
            border-top: unset;
        }        
    }
}

trovata-account-summary-selectors .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

trovata-account-summary-selectors .mat-mdc-form-text-infix {
	padding: 0 17px 0 17px !important;
	border-top: unset;
}

trovata-account-summary-selectors .mat-form-field-disabled .mat-mdc-form-text-infix {
	padding: 0 0 0 17px !important;
	border-top: unset;
}

trovata-account-summary-selectors .mdc-line-ripple{
	display: none;
}

trovata-account-summary-groupings{

    mat-chip.primary-chip,
    .mat-mdc-chip.primary-chip,
    .mat-mdc-standard-chip.primary-chip {
      background: $primary-light-color-chip-v2 !important;
      border: none;
      border-radius: 14px;
      color: $primary-color-chip-v2 !important;
      display: inline-block;
      min-height: 24px !important;
      height: 24px !important;
      box-sizing: content-box !important;

      .mdc-evolution-chip__text-label{
        color: $primary-color-chip-v2 !important;
      }
    
      .mat-icon {
        vertical-align: middle;
        height: 16px;
        width: 16px;
        font-size: 16px;
      }
    }
    .mdc-linear-progress__bar-inner {
      border-color: $primary-color-chip-v2;
    }
}


trovata-home-transactions-snack-preview{
  .mdc-data-table {
    width: 100%;
    table-layout: fixed; /* Ensures columns respect defined widths */
  }
  
  .mat-mdc-table .mdc-data-table__cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }
}