// Custom Theming for Angular Material
@use '@angular/material' as mat;
@use 'sass:map';
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
@include mat.all-component-typographies();
@include mat.core();
@import '../helpers/variables';
@import '_env-variables';
@import '_env-palette';
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$trovata-angular-app-primary: mat.define-palette($mat-palette, A100);
$trovata-angular-app-accent: mat.define-palette($mat-palette, A200);

// The warn palette is optional (defaults to red).
$trovata-angular-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$trovata-angular-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $trovata-angular-app-primary,
      accent: $trovata-angular-app-accent,
      warn: $trovata-angular-app-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: 'AkkuratLLWeb',
        $button: mat.define-typography-level(14px, 20px, $font-weight-bold),
      ),
  )
);

$trovata-angular-app-theme: map.set($trovata-angular-app-theme, color, foreground, text, $dark-primary-text);
$trovata-angular-app-theme: map.set($trovata-angular-app-theme, foreground, text, $dark-primary-text);

// @debug $trovata-angular-app-theme;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($trovata-angular-app-theme);

.snack-theme {
  $typography-config: mat.define-typography-config(
    $font-family: 'AkkuratLLWeb',
    $headline-5: mat.define-typography-level(28px, 32px, 400),
    $body-1: mat.define-typography-level(16px, 24px, $font-weight-bold),
    $body-2: mat.define-typography-level(14px, 20px, 400),
    $headline-6: mat.define-typography-level(14px, 20px, $font-weight-bold),
    $caption: mat.define-typography-level(12px, 16px, 400),
    $button: mat.define-typography-level(14px, 20px, $font-weight-bold),
  );

  $theme: mat.define-light-theme(
    (
      typography: $typography-config,
    )
  );
  @include mat.all-component-typographies($theme);
  @include mat.core();

  .mat-mdc-card {
    border: none;
  }

  .mat-caption {
    color: rgb(0, 0, 0, 0.56);
    overflow: hidden;
  }

  .mat-headline-6 {
    margin: 0;
  }

  .mat-headline-5 {
    margin: 0;
  }

  .bold {
    font-weight: 700;
  }
}

// Override MDC Progress Bar background (negative space)
.mdc-linear-progress {
  --mdc-linear-progress-track-color: #00000012 !important; /* Change to your desired color */
}
// Specific component overrides, pieces that are not in line with the general theming
body { @include mat.checkbox-density(-3); }

.mat-mdc-form-field {
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, .56);
}

mat-list-item {
  &.mat-mdc-list-item:not(.mat-mdc-list-item-interactive),
  &.mat-mdc-list-option:not(.mat-mdc-list-item-interactive) {
    cursor: pointer;
  }

  &.mat-mdc-list-item:not(.mat-mdc-list-item-interactive):hover::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
  }

  // this should be done with list-theme density, but I can't figure it out
  &.mdc-list-item.mdc-list-item--with-three-lines{
    height: 72px;
  }

  &.mdc-list-item.mdc-list-item--with-two-lines{
    height: 50px;
  }

  &.mdc-list-item--with-two-lines .mdc-list-item__primary-text,
  &.mdc-list-item--with-three-lines .mdc-list-item__primary-text  {
  margin-top: -8px;
  }

  &.mdc-list-item.unclickable:hover {
    cursor: default;
  }

  &.mdc-list-item.unclickable:hover::before {
    opacity: 0;
  }

  &.mdc-list-item__end {
    display: none;
  }
}

.mat-mdc-list-option.multiple-bank-option .mdc-checkbox {
  margin-top: 35px;
}

.mat-mdc-menu-panel {
  max-width: 300px !important;
}

.mat-mdc-menu-item.red-text {
  color: #ff5252
}

.mat-mdc-menu-content:not(:empty) .mdc-list {
	padding: 0px;
}
.mat-mdc-list-option.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-right: 0px;
}

// Mat Snackbar override (otherwise min-width is 344px)
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  min-width: 10px !important;
}

// MDC override to prevent the radio button from showing up in mat-lists
body { @include mat.radio-density(-1); }
body { @include mat.slide-toggle-density(-1); }

// Mat Slide toggle overrides
.mdc-switch__icons {
  display: none;
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #{$primary-color};
  --mdc-switch-selected-handle-color: #{$primary-color};
  --mdc-switch-selected-hover-state-layer-color: #{$primary-color};
  --mdc-switch-selected-pressed-state-layer-color: #{$primary-color};
  --mdc-switch-selected-focus-handle-color: #{$primary-color};
  --mdc-switch-selected-hover-handle-color: #{$primary-color};
  --mdc-switch-selected-pressed-handle-color: #{$primary-color};
  --mdc-switch-selected-focus-track-color: #{$primary-light-color};
  --mdc-switch-selected-hover-track-color: #{$primary-light-color};
  --mdc-switch-selected-pressed-track-color: #{$primary-light-color};
  --mdc-switch-selected-track-color: #{$primary-light-color};
}
.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #{$primary-light-color};
  --mdc-switch-disabled-unselected-handle-color: #{$primary-light-color};
  --mdc-switch-disabled-selected-track-color: #{$primary-light-color};
  --mdc-switch-disabled-unselected-track-color:  #{$primary-light-color};
  --mdc-switch-unselected-focus-state-layer-color:  #{$primary-light-color};
  --mdc-switch-unselected-pressed-state-layer-color:  #{$primary-light-color};
  --mdc-switch-unselected-hover-state-layer-color:  #{$primary-light-color};
  --mdc-switch-unselected-focus-track-color: #9E9E9E;
  --mdc-switch-unselected-hover-track-color: #9E9E9E;
  --mdc-switch-unselected-pressed-track-color: #9E9E9E;
  --mdc-switch-unselected-track-color: #9E9E9E;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #fafafa;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}

// Handle buttons appropriately, with respect to line-height
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-mdc-outlined-button[disabled]:has(.mat-spinner) {
  background-color: rgb(0, 0, 0, 0.12);
}

.mat-mdc-icon-button {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, .56);
}

.snack-container .title-row
.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

wj-flex-grid .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
  width: 16px;
  height: 16px;

  .material-icons {
    font-size: 16px;
  }

  .material-icons-outlined {
    font-size: 16px;
  }

  .mat-mdc-button-touch-target {
    width: 16px;
    height: 16px;
  }
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: AkkuratLLWeb;
  }
}

.mat-progress-bar .mat-progress-bar-fill::after {
  background-color: $chart-accent-color;
}

.mat-progress-bar-buffer,
.mat-progress-bar .mat-progress-bar-buffer {
  background: rgba(0, 0, 0, 0.04);
}

.mat-progress-bar-background,
.mat-progress-bar .mat-progress-bar-background {
  animation: none;
  background-color: #eceff1;
  fill: #eceff1;
}

.mdc-tab--active {
  .mat-mdc-chip.flat,
  .mat-chip.mat-mdc-standard-chip.flat,
  .mat-chip.flat:hover,
  .mat-chip.flat.mat-standard-chip:hover,
  .mat-chip.flat:active,
  .mat-chip.flat.mat-standard-chip:active {
    background: #e4f2ff;
  }
}

.mat-mdc-form-field.readonly-input .mat-mdc-form-field-flex .mat-form-field-outline,
.mat-form-field.readonly-input .mat-mdc-form-field-flex .mat-form-field-outline:hover,
.mat-form-field.readonly-input .mat-mdc-form-field-flex .mat-form-field-outline:focus {
  color: $mat-form-field-outline-color !important;
}

// mat form field
.mat-form-field.readonly-input .mat-form-field-flex .mat-form-field-outline{
  color: rgba(0, 0, 0, 0.16);

  &:hover,
  &:focus {
    color: $primary-color;
  }

  div {
    border-width: 1px solid currentColor;
  }
}

.mat-mdc-form-field.readonly-input .mat-mdc-floating-label {
  .mat-form-field.mat-focused {
    .mat-form-field-label {
      color: $primary-color !important;
    }
    .mat-form-field-ripple {
      background-color: $primary-color !important;
    }
    .mat-form-field-required-marker {
      color: $primary-color !important;
    }
  }
}

// need to be converted to mdc styles
 .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
 .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
 .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-width: 1px !important;
  color: rgba(0, 0, 0, .56) !important;
}

 .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-start,
 .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-end,
 .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-width: 2px !important;
  color: $primary-color !important;
}

.mat-form-field.mat-focused {
    .mat-form-field-label {
        color: $primary-color !important;
    }
    .mat-form-field-ripple {
        background-color: $primary-color !important;
    }
    .mat-form-field-required-marker {
        color: $primary-color !important;
    }
}

input{
  caret-color: $primary-color !important;;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $mat-card-light-border;
}

.mat-form-field.readonly-input .mat-form-field-label {
  color: $light-text-color;
}
// need to be converted to mdc styles (end)

.mat-mdc-form-field.readonly-input input {
  color: $disabled-color;
}

.mat-form-field.smaller {
  line-height: 14px;
  font-size: 14px;

  .mat-form-field-label {
    overflow: visible;
  }
}

// mat-mdc-input
.mat-mdc-input-element:disabled.black {
  color: $dark-primary-text;
}

// mat-select
.disabled-color {
  color: $disabled-color !important;
}

.cdk-overlay-pane:has(.mat-mdc-select-panel):not(:has(.mat-mdc-select-panel.w-auto)) {
    width: auto !important;
}

mat-nav-list.mat-mdc-list-base,
mat-list.mat-mdc-list-base{
  padding: 0px;

  .red-text,
  .red-text .material-icons-outlined,
  .red-text mat-icon {
    color: #ff5252
  }

  & .mat-icon,
  & .material-icons-outlined {
    scale: 0.83333;
    color: $light-text-color;
  }
}

.mdc-list-item:hover .mdc-list-item__primary-text.red-text {
  color: #ff5252
}

.mdc-list-item__primary-text.red-text,
.mdc-list-item__primary-text.red-text .mat-icon {
  color: #ff5252
}

.trovata-inst-placeholder.mat-mdc-option .mdc-list-item__primary-text {
  margin-right: 0;
}

.mdc-list-item__secondary-text.list-description {
  padding: 8px 32px 8px 16px;
    word-wrap: break-word;
    white-space: normal;
}

.disabled-color {
  color: $disabled-color;
}

.mat-mdc-icon-button.close-form-button {
  margin-left: -8px;
  margin-top: -8px;
}

.mat-snack-bar-container {
  display: flex !important;
  justify-content: center !important;
  min-width: unset !important;
}

.mat-mdc-card.flat {
  box-shadow: none;
  overflow: hidden;
}

.mat-mdc-card.flat.snack-card {
  width: 100%;
  height: 100%;
  border-width: 0px;
  background: #ffffff;

  .highcharts-axis.highcharts-xaxis {
    display: none;
  }
}

mat-chip.flat {
  transition: none !important;
  box-shadow: none !important;
}

mat-chip.grey-chip,
.mat-mdc-chip.grey-chip,
.mat-mdc-standard-chip.grey-chip {
  mat-icon{
    color: #00000099;
  }
  background: #00000008 !important;
  border: 1px solid #00000008;
  border-radius: 14px;
  color: #00000099 !important;
  display: inline-flex;
  .mdc-evolution-chip__text-label{
    color: #00000099 !important;
  }
  .mat-icon {
    color: #00000099 !important;
  }
}

mat-chip.blue-chip,
.mat-mdc-chip.blue-chip,
.mat-mdc-standard-chip.blue-chip {
  background: rgba(228, 242, 255, 1) !important;
  border: 1px solid rgba(228, 242, 255, 0.3);
  border-radius: 14px;
  color: #0061D1 !important;
  display: inline-flex;
  .mdc-evolution-chip__text-label{
    color: #0061D1 !important;
  }
  .mat-icon {
    color: #0061D1 !important;
  }
}

mat-chip.red-chip,
.mat-mdc-chip.red-chip,
.mat-mdc-standard-chip.red-chip {
  background: rgba(255, 82, 82, 0.04) !important;
  border: 1px solid rgba(255, 82, 82, 0.3);
  border-radius: 14px;
  display: inline-block;
  .mdc-evolution-chip__text-label{
    color: #ff5252 !important;
  }
}


mat-chip.green-chip,
.mat-mdc-chip.green-chip,
.mat-mdc-standard-chip.green-chip {
  background: rgba(13, 178, 95, 0.04) !important;
  border: 1px solid rgba(13, 178, 95, 0.3);
  border-radius: 14px;
  display: inline-block;
  .mdc-evolution-chip__text-label{
    color: #0db25f !important;
  }
}

mat-chip.resource-chip,
.mat-mdc-chip.resource-chip,
.mat-mdc-standard-chip.resource-chip {
  background: rgba(0, 0, 0, 0.04) !important;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  .mdc-evolution-chip__text-label{
    color: $dark-primary-text !important;
  }
}

mat-chip.brown-chip,
.mat-mdc-chip.brown-chip,
.mat-mdc-standard-chip.brown-chip {
  background: rgba(255, 144, 0, 0.04) !important;
  border: 1px solid #ffdbaa;
  border-radius: 14px;
  color: #a95c00 !important;
  display: inline-block;
  min-height: 20px !important;
  height: 20px !important;
  box-sizing: content-box !important;

  .mdc-evolution-chip__text-label{
    color: #a95c00 !important;
  }

  .mat-icon {
    vertical-align: middle;
    height: 16px;
    width: 16px;
    font-size: 16px;
  }
}

mat-chip.green-chip,
mat-chip.red-chip,
mat-chip.brown-chip,
mat-chip.resource-chip,
.mat-mdc-chip.green-chip,
.mat-mdc-chip.red-chip,
.mat-mdc-chip.resource-chip,
.mat-mdc-standard-chip.green-chip,
.mat-mdc-standard-chip.red-chip,
.mat-mdc-standard-chip.resource-chip {
  margin: 7px 3px 7px 3px;
  padding: 6px 0px;
  min-width: 40px;
  line-height: 20px !important;
  min-height: 20px !important;
  height: 20px !important;
  font-size: 14px;
  box-sizing: content-box !important;

  .mat-icon {
    vertical-align: middle;
    font-size: 14px;
    height: 14px;
    width: 14px;
    padding: 3px;
  }
}

mat-chip.flat:hover,
mat-chip.flat:hover:active {
  background-color: $mat-chip-color !important;
}

mat-chip.flat.mat-mdc-standard-chip::after {
  background: $mat-chip-color !important;
}

.mdc-evolution-chip-set__chips {
  max-width: 100% !important;
  }

  .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
  .mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
  }

.iframe-dialog-container {
	height: 100%;
	width: 100%;
	padding: 24px;
	max-width: 100vw !important;
}

.iframe-dialog-backdrop {
	margin-left: 250px;
}

.centered-small-dialog {
  box-shadow:
    0px 16px 24px rgba(0, 0, 0, 0.14),
    0px 6px 30px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.2);

	.mat-mdc-dialog-container {
		border-radius: 8px;
	}
}

.side-dialog-container .mat-mdc-dialog-container {
	border-radius: 0px;
}

.subscription-side-dialog-container .mat-mdc-dialog-container {
	border-radius: 0px;
	padding: 24px 0;
}

.subscription-compare-table-info-toggle-group .mat-button-toggle-label-content {
  line-height: 36px;
}

.mat-mdc-option.notSelectable:first-child .mat-pseudo-checkbox {
  display: none;
}

.mat-mdc-option.notSelectable.mat-option-disabled {
  color: $dark-primary-text;
}

.search-mat-form-field {
  margin-left: -20px;
  margin-top: 25px;
  margin-bottom: 15px;
  width: 83%;
  display: block;
  position: relative;
  color: #1d242e;
}

.mat-content > mat-panel-title,
.mat-content > mat-panel-description {
  flex: 0 0 auto;
}

.mat-sort-header-button {
  min-width: 70% !important;
  max-width: 85% !important;
  justify-content: center;
}

.flat-expansion-panel.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
  border-style: solid;
  border-width: 1.3px;
  border-color: $mat-card-light-border;

  .mat-expansion-panel-body {
    padding: 0px;
  }
}

.deactivated-mat-card {
  background: #09080842 !important;
}

.mat-step-icon-state-done {
  background-color: $primary-color !important;
}

// spinner
.button-spinner-hide-text {
  opacity: 0 !important;
  }

.button-spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-progress-spinner.primary-color circle,
.mat-spinner.primary-color circle {
  stroke: $primary-color;
}

.white-spinner.mat-progress-spinner circle,
.white-spinner.mat-spinner circle {
    stroke: white;
}

.light-spinner.mat-progress-spinner circle,
.light-spinner.mat-spinner circle {
  stroke: rgba(0, 0, 0, 0.56);
}

// mdc tables
.mat-mdc-table {
  .mdc-data-table__cell {
    box-sizing: content-box;
    font-weight: unset;
    padding: 0px 12px;
  }

  th.mat-mdc-header-cell {
    align-content: center;
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type {
      padding-left: 24px;
  }

  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type {
      padding-right: 24px;
  }

  th.mat-mdc-header-cell.mat-column-radioSelect,
  td.mat-mdc-cell.mat-column-radioSelect {
      padding: 0px 8px 0 16px;
      width: 26px;
  }

  tr.mat-row,
  .mdc-data-table__row {
    height: 48px;

  }

  .mat-mdc-footer-cell {
    padding-left: 24px;
    padding-right: 24px;
  }
}
// mat-mdc-tab

.mat-mdc-dialog-container .mat-mdc-tab {
  min-width: 80px;
}

mat-tab-header .mat-mdc-tab {
  min-width: 160px;
}

.mat-mdc-tab.mat-mdc-tab-disabled {
  cursor: auto;

    .mdc-tab__ripple::before {
    background-color: transparent !important;
  }

  &:has(button.mat-mdc-icon-button:not(:disabled)) {
    opacity: 1;
  }
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  --mat-tab-header-pagination-icon-color: rgba(0, 0, 0, .56);
}

.mat-mdc-tab-body {
  margin-top: 25px;
}

.mdc-tab__text-label {
  letter-spacing: 0px;
}

.mat-sort-header-content {
  color: black;
}

html {
  --mat-datepicker-calendar-date-selected-state-background-color: #{$primary-color};
  --mat-datepicker-calendar-date-in-range-state-background-color: #{$date-picker-hover};
}

.no-subscript{
  .mat-mdc-form-field-subscript-wrapper{
		display: none;
	}
}
